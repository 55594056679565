




















































































































import ListagemDeCashback from '@/components/cliente/ListagemDeCashback.vue';
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue';
import { CashbackListagem } from '@/models';
import { aplicarMascaraParaCpfOculto, formatarCnpjOuCpf, formatarMoeda } from '@/shareds/formatadores';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { FindCashbackUseCase } from '@/usecases';
import axios, { CancelTokenSource } from 'axios';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import DialogoDeCsvClientes from '../clientes/DialogoDeCsvClientes.vue';
import { dateToPtBrFormat } from '@/shareds/date/date-utils';
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue';
import DialogoDeCsvCashback from './DialogoDeCsvCashback.vue';


@Component({
	components: {
		ListagemDeCashback,
		DataTableDeCrud,
		SeletorDeLojasDoUsuario,
		DialogoDeCsvCashback,
	},
})
export default class TelaDeCashback extends Vue {
	@Ref() dialogoDeCsvCliente!: DialogoDeCsvClientes
	@Ref() dialogoCsv!: DialogoDeCsvCashback

	headers = [
		{ text: 'Nome', value: 'razaoSocialOuNome' },
		{ text: 'Loja', value: 'nomeLoja' },
		{ text: 'Telefone', value: 'telefone' },
		{ text: 'CPF/CNPJ', value: 'cnpjOuCpfFormatado' },
		{ text: 'Total cashback', value: 'totalCashback' },
		{ text: 'Compra mínima R$', value: 'compraMinimaParaUsarCashbackTotal' },
		{ text: 'Primeiro vencimento', value: 'primeiroVencimentoFormatado' },
		{ text: 'Dias para expirar', value: 'diasParaExpirarFormatado' },
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	totalRegistros = -1

	carregando = false
	mostrar = false

	busca = ''
	lojaId: string | null = null

	editando = true
	expirados = false

	listaDeCashback: CashbackListagem[] = []
	cashback: CashbackListagem | null = null

	findUseCase = new FindCashbackUseCase()
	cancelToken: CancelTokenSource | null = null

	formatarMoeda = formatarMoeda

	created() {
		this.buscar()
	}

	@Watch('lojaId')
	onChangeLoja() {
		this.buscar()
	}

	@Watch('expirados')
	onChangeExpirados() {
		this.buscar()
	}

	async buscar() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			const params = {
				page: this.paginacao.page - 1,
				busca: this.busca || null,
				size: this.paginacao.itemsPerPage,
				lojaId: this.lojaId,
				expirados: this.expirados,
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const page = await this.findUseCase.findListagemDeCashback(params, axiosConfig)
			this.totalRegistros = page.totalElements
			this.listaDeCashback = page.content
			this.carregando = false
		} catch (error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
			this.carregando = false
		}
	}

	async abrirDialogoDeCashback(indice: any) {
		this.cashback = this.listaDeCashback[indice]
		this.mostrar = true
		
	}

	get itensFormatados() {
		return this.listaDeCashback.map(cashback => ({
			...cashback,
			cnpjOuCpfFormatado: cashback.cnpjOuCpf.length <= 11
				? aplicarMascaraParaCpfOculto(cashback.cnpjOuCpf)
				: formatarCnpjOuCpf(cashback.cnpjOuCpf),
			primeiroVencimentoFormatado: cashback.primeiroVencimento != null ? dateToPtBrFormat(cashback.primeiroVencimento) : '-',
			diasParaExpirarFormatado: cashback.diasParaExpirar != null ? cashback.diasParaExpirar : '- -',
			compraMinimaParaUsarCashbackTotal: formatarMoeda(cashback.compraMinimaParaUsarCashbackTotal),

		}))
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscar

	@Watch('busca')
	onChangeBusca() {
		this.paginacao.page = 1
		this.buscar()
	}
}
