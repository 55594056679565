































































































import {  ClienteServiceAdapter, ImportaEstoquesUseCase } from '@/usecases';
import { Component, Prop, Vue } from 'vue-property-decorator' ;
import { Inject } from 'inversify-props'
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import {  Loja } from '@/models';
import { uploadFilesToS3 } from '@/shareds/s3/files';

@Component
export default class DialogoDeCsvClientes extends Vue {
	@Inject('ClienteServiceAdapter')
	private clienteService!: ClienteServiceAdapter

	@Prop() loja!: Loja
	
	mostra = false
	tab: null | number = null
	tabs = [
		{ action: 'Importar', icon: 'mdi-file-upload' },
	]
	importaEstoquesUseCase = new ImportaEstoquesUseCase()
	importando = false
	arquivoContemCabecalho = false
	arquivoCsv: File[] = []

	camposCsv = {
		razaoSocialOuNome: 'razaoSocialOuNome',
		cnpjOuCpf: 'cnpjOuCpf',
		dataDeNascimento: 'dataDeNascimento',
		email: 'email',
		rua: 'rua',
		numero: 'numero',
		cep: 'cep',
		bairro: 'bairro',
		complemento: 'complemento',
		cidade: 'cidade',
		uf: 'uf',
		inscricaoEstadual: 'inscricaoEstadual',
		telefones: 'telefones',
		tipoDeCliente: 'tipoDeCliente',
		codigoIBGE: 'codigoIBGE',
		genero: 'genero',
		profissao: 'profissao_codigo',
	}
	arquivo = 'File'

	tabelaNome: string | null = null
	tabelaId: string | null = null
	selecionado: boolean | null = null
	inputFile: HTMLInputElement | null = null

	async mostrar(tabelaId?: string, tabelaNomeRecebido?: string){
		this.tabelaId = tabelaId ? tabelaId : null
		this.tabelaNome = tabelaNomeRecebido ? tabelaNomeRecebido : null
		this.mostra = true
	}
	cancelar() {
		this.importando = false
		this.mostra = false
		this.arquivoCsv = []
		this.arquivoContemCabecalho = false
	}

	async concluirImportacao(){
		if (this.arquivoCsv.length == 0) {
			return AlertModule.setError("É obrigatório selecionar um arquivo");
		}
		try {
			this.importando = true;

			const informacoesDosArquivos = await uploadFilesToS3(
				this.arquivoCsv,
				`importacao-de-clientes`,
			);
			const urlDoArquivoCsv = informacoesDosArquivos.map(({ config }) => config.url)[0];

			if (!urlDoArquivoCsv) {
				return AlertModule.setError("Ocorreu um erro interno, contate o suporte");
			}

			await this.clienteService.importaItens(
				urlDoArquivoCsv,
				this.arquivoContemCabecalho,
			)

			AlertModule.setSuccess('Itens em processamento')
			this.arquivoCsv = [];
			this.mostra = false;
			this.arquivoContemCabecalho = false;
		} catch (error) {
			AlertModule.setError(error)
		} finally { 
			this.importando = false;
		}
	}

	clickCsv() {
		this.inputFile = document.querySelector('.form-control-file')
		if(this.inputFile != null)
			this.inputFile.click();
		
	}

	async baixarModelo() {
		const rows = [
			[
				'nome','cnpj_cpf','data_nascimento','email','rua','numero','cep','bairro','complemento','cidade','uf','incricao_estadual','telefone','tipo_cliente','cod_ibge', 'genero', 'profissao_codigo',
			],
		]

		const csvContent = "data:text/csv;charset=utf-8," 
			+ rows.map(e => e.join(";")).join("\n")

		const encodedUri = encodeURI(csvContent)
		const link = document.createElement("a")
		link.setAttribute("href", encodedUri)
		link.setAttribute("download", "modelo_importacao_cliente.csv")
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

}
